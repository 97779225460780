import React from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import { Container, Nav } from 'react-bootstrap';
import ScrollspyNavItemHome from "../layout/ScrollspyNavItemHome";
import SubNavigationTeaserButton from "../layout/SubNavigationTeaserButton";
import Homeslider from "../sections/Homeslider";
import Teilnehmer from "../sections/Teilnehmer";
import MultiPressekonferenz from "../sections/MultiPressekonferenz";
import Kontaktmesse from "../sections/Kontaktmesse";
import Teilnahme from "../sections/Teilnahme";
import Themen from "../sections/Themen";
import ZuGast from "../sections/ZuGast";
import Stimmen from "../sections/Stimmen";
import RbsPage from '../controls/RbsPage';
import Firmen2025 from '../sections/2025/Firmen2025';

const Startseite = () => {

  return (
    <RbsPage>

      <nav className="navbar sticky-md-top subnav">
        <Container>
          <ScrollspyNav scrollTargetIds={["MultiPressekonferenz", "Kontaktmesse", "Teilnahme", "Themen", "ZuGast"]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true">
            {/* <ScrollspyNav scrollTargetIds={["Teilnehmer", "MultiPressekonferenz", "Kontaktmesse", "Teilnahme", "Themen", "ZuGast"]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true"> */}
            <Nav variant="pills">
              <ScrollspyNavItemHome />
              {/* <Nav.Item>
              <Nav.Link href="#Teilnehmer">Teilnehmer</Nav.Link>
            </Nav.Item> */}
              <Nav.Item>
                <Nav.Link href="#MultiPressekonferenz">Multi-Pressekonferenz</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Kontaktmesse">Pressegespräche</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Teilnahme">Teilnahme</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Themen">Themen</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#ZuGast">Fpt zu Gast</Nav.Link>
              </Nav.Item>
            </Nav>
          </ScrollspyNav>
        </Container>
        <SubNavigationTeaserButton />
      </nav>
      <section id="Homeslider" className="custom-section-grey"><Homeslider/></section>   
      <section id="Teilnehmer"><Firmen2025 /></section>
      <section id="MultiPressekonferenz"><MultiPressekonferenz /></section>
      <section id="Kontaktmesse"><Kontaktmesse /></section>
      <section id="Teilnahme" className="custom-section-grey"><Teilnahme /></section>
      <section id="Themen"><Themen /></section>
      <section id="ZuGast" className="custom-section-grey"><ZuGast /></section>
      <section id="Stimmen" className="custom-section-dark-grey"><Stimmen /></section>

    </RbsPage>
  )

}

export default Startseite;
