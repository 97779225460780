import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import SubNavigation from '../layout/SubNavigation';

const Kontakt = () => {

    return (
        <React.Fragment>
            <nav className="navbar sticky-top subnav">
                <Container>
                    <SubNavigation defaultActiveKey="Kontakt" />
                </Container>
            </nav>
            <section className="custom-section-grey">
                <Container>
                    <Row className="pb-4">
                        <Col>
                            <h1>Kontakt</h1>
                        </Col>
                    </Row>
                    <Row className="gx-5">
                        <Col className="col-12 col-lg-6">
                            <h2>Redaktionsbüro Stutensee</h2>
                            <p>Brücke zur Presse – Kleine und mittelständische Unternehmen finden meist nur schwer
                                den Zugang zur Fachpresse. Wir vom Redaktionsbüro Stutensee (rbs) haben es uns deshalb zur
                                Aufgabe gemacht, die Brücke zwischen Industrie und Fachpresse zu schlagen, indem wir Firmen
                                aus dem Umfeld der Automatisierungstechnik bei ihren Presseaktivitäten unterstützen.
                            </p>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <h2>Veranstalter</h2>
                            <p>
                                <strong>rbs</strong> Redaktionsbüro Stutensee<br />
                                Alexander Homburg<br /><br />

                                Am Hasenbiel 13-15<br />
                                D-76297 Stutensee<br /><br />

                                Telefon: +49 7244-73969-0<br />
                                Telefax: +49 7244-73969-9<br /><br />

                                Internet: <a href="http://www.rbsonline.de">www.rbsonline.de</a><br />
                                E-Mail: <a title="Mail ans rbs" href="mailto:kontakt@rbsonline.de">kontakt@rbsonline.de</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )

}

export default Kontakt;
